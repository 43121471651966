.ckwLicenseRestrictionsModal {
  .modal-dialog {
    max-width: 610px;
    width: 100%;
  }

  .modal-content {
    .modal-body {
      color: #1F1F1F;
      font-size: 18px;
      font-weight: 400;
      padding: 20px 60px;
      padding-bottom: 20px;
      letter-spacing: 0.54px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      padding: 35px 60px;

      .modal-title {
        margin: 0;
      }
    }

    .modal-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
    }
  }
}