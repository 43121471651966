.is-large-button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 11px 20px;
  background: transparent;
  border: 2px solid rgba(31, 31, 31, 0.25);
  border-radius: 48px;
  font-size: 14px;
  font-weight: 500;
  color: #1F1F1F;
  box-shadow: none;
  line-height: 18px;

  &:hover:hover:not(:disabled):not(.active) {
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }

  &:disabled {
    border-color: #767676 !important;
    color: #767676 !important;
  }
}