.error-filter__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url('../../../assets/images/svg/arrow-down-for-native-select.svg');

    position: absolute;
    top: 32px;
    right: 5px;
  }

  select {
    appearance: none;
    padding: 0 5px;
  }

  span {
    color: rgba(31, 31, 31, 0.50);
    font-size: 14px;
  }

  .additional-filters__select {
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    line-height: 22px;
    color: rgba(31, 31, 31, 0.50);
    border: 1px solid transparent;
    background-color: rgba(31, 31, 31, 0.10);
    padding: 5px 10px 5px 16px;
    width: 250px;

    option {
      color: rgba(31, 31, 31, 0.50);
    }
  }
}

.reboot-dropdown-wrapper {
  margin-right: 15px;

  .drop-down-list-reboot .drop-down-list-reboot-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}