.switch-tooltip+.callbackUrlField {
  input.inputField {
    padding-right: 51px;
  }
}

.customTextField {
  i.la.la-copy {
    position: absolute;
    right: 30px;
    bottom: 5px;
    color: #f01;
    cursor: pointer;
    font-size: 22px;
  }

  .delete-token-btn {
    background: transparent;
    border: none;
    cursor: pointer;

    i {
      font-size: 22px;
      color: #f01;
    }

  }
}