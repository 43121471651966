.breadcrumbs-container {
    margin: 18px 0 21px 0;
}

.breadcrumbs {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;

    &-icons {
        color: var(--primary-color);
        height: 16px;
        width: 17px;
        text-align: center;
    }

    &-crump {
        color: rgba(31, 31, 31, 0.5);

        &:hover {
            color: rgba(31, 31, 31, 0.5);
        }
    }
}