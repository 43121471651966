.react-table-v2 {
  position: relative;
  overflow: hidden;

  .table {
    background-color: transparent;
    border: 1px solid rgba(31, 31, 31, 0.10);
    border-collapse: collapse;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;

    margin-bottom: 0px;

    .tr {
      align-items: stretch;
      display: flex;
      padding: 0 10px;

      .select-col,
      .expander {
        flex: 0 0 46px !important;
      }
    }

    .thead {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid rgba(31, 31, 31, 0.10);
      overflow: visible !important;

      .th {
        transition-property: width, min-width, padding, opacity;
        position: relative;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        color: rgba(31, 31, 31, 0.5) !important;
        font-weight: 400;
        line-height: 2.5;

        .react-table-header-cell {
          min-height: 60px;
          overflow: hidden;
          padding: 0 5px;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          column-gap: 10px;
        }

        .edit-column-container {
          margin: 0 !important;
        }
      }

      .th.select-col {
        .react-table-header-cell {
          padding: 0;
        }
      }

      .resizer {
        position: absolute;
        top: 0;
        height: 100%;
        right: 0px;
        width: 10px;
        cursor: col-resize;
        user-select: none;
        touch-action: none;
        background: transparent;

        &:after {
          content: "";
          position: absolute;
          left: 50%;
          width: 2px;
          top: 0;
          bottom: 0;
          background: rgba(31, 31, 31, 0.10);
          transform: translate(-50%, 0);
        }
      }

      .resizer.isResizing {
        opacity: 1;
      }

      @media (hover: hover) {
        .resizer {
          opacity: 0;
        }

        *:hover>.resizer {
          opacity: 1;
        }
      }
    }

    .tbody {
      overflow: visible !important;
      display: flex;
      flex-direction: column;

      .tr {
        color: #1F1F1F;
        font-weight: 400;
        font-size: 16px;
        border-top: 1px solid rgba(31, 31, 31, 0.10);

        &.sub-row {
          border-top: 0px solid rgba(31, 31, 31, 0.10);
        }

        &.selected,
        &.expanded,
        &:hover {
          background-color: rgba(31, 31, 31, 0.05);
        }

        .td {
          overflow: hidden;
          transition-property: width, min-width, padding, opacity;
          padding: 0 5px;
          align-items: center;
          line-height: 3.75;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .react-table-expander {
    border: none;
    display: block;
    background-color: transparent;
  }

  .table-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 2em;
    color: rgba(31, 31, 31, 0.50);
    background: rgba(31, 31, 31, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
}