.supported-brands-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  label {
    width: 100%;
    margin-bottom: 10px;
    background-color: rgba(31, 31, 31, 0.10);
    border-radius: 4px;
    padding: 15px 16px;
    color: #1F1F1F;
    font-weight: 400;
    cursor: pointer;
  }

  img {
    margin-right: 10px;
    max-width: 40px;
    max-height: 40px;
  }

  .customRadio input+.checkmark {
    border-color: var(--primary-color);
  }
}

.visibility-hidden {
  visibility: hidden;
}

.login-into-account-wrapper {
  margin-top: 50px;
  padding: 0 36px;

  .content-wrapper {
    display: flex;
    gap: 12px;

    img {
      align-self: flex-start;
      height: 40px;
      width: 40px;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #1F1F1F;
      }

      .description {
        font-size: 14px;
        color: #1F1F1F;
      }

      .creds-form {
        margin-bottom: 130px;
        position: relative;
        margin-top: 30px;

        .field-wrapper {
          margin-bottom: 30px;
        }

        .m-input {
          width: 100%;
        }

        .error {
          position: absolute;
          bottom: -20px;
          left: 0;
        }
      }
    }
  }

  .mb-50px {
    margin-bottom: 50px;
  }

  .horizontal-line {
    height: 2px;
    width: 100%;
    margin: 50px 0;
    background-color: #25272d;
    border-radius: 4px;
  }
}

.select-car-wrapper {
  padding: 0 30px;

  .vehicle-card {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #e9e9e9;
    border-radius: 4px;
    padding: 20px 20px 20px 16px;
    margin-bottom: 10px;


    .vehicle-title {
      color: #1F1F1F;
      font-size: 14px;
      font-weight: 500;

      &.sub {
        font-weight: 400;
        opacity: 0.5;
      }

      &.label {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 2px;
        opacity: 0.5;

      }
    }

    .edit-car-btn {
      margin-left: auto;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .btn-wrapper {
      display: flex;
      margin-left: auto;
      gap: 21px;
    }

    .save-btn,
    .cancel-btn {
      height: 28px;
      width: 28px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
      border: none;
      border-radius: 100%;
    }

    .save-btn {
      color: var(--primary-color);

      &:hover {
        color: #fff;
        background-color: var(--primary-color);
      }
    }

    .cancel-btn {

      &:hover {
        color: #fff;
        background-color: #f53920;
      }
    }

    input.vehicle-title-input {
      width: 70%;
      font-size: 14px;
      border: none;
      color: #fff;
      border-bottom: 1px solid #707070 !important;

      &:focus {
        font-size: 14px !important;
        border-bottom: 1px solid #707070 !important;
      }
    }

    .modern-radio-container {
      margin-right: 16px;
      cursor: pointer;
    }

    .radio-outer-circle {
      width: 20px;
      height: 20px;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      transition: all 0.1s linear;
    }

    .radio-inner-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--primary-color);
      transition: all 0.1s linear;
    }

    .unselected {
      border: 1px solid var(--primary-color);
    }

    .unselected-circle {
      width: 0;
      height: 0;
    }
  }

  .no-cars {
    color: #1F1F1F;
    font-size: 16px;
    text-align: center;
  }
}

.url-tab-wrapper {
  padding: 20px 30px 50px 30px;

  .tesla-link {
    margin-bottom: 55px;
    color: #1F1F1F;
    font-size: 14px;

    a {
      color: #F01;
    }
  }

  .example-url {
    font-size: 12px;
    color: #1F1F1F;
    margin-bottom: 13px;
  }

  img {
    margin-bottom: 55px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}