.documentation-wrapper {
  .documentation-header-container {
    margin: 50px 0 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .documentation-header {
    display: flex;
    gap: 10px;

    .documentation-header-title {
      font-size: 18px;
      color: #1F1F1F;
      margin: 0;
      font-weight: 700;
    }

    .documentation-header-subtitle {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      background-color: #F01;
      padding: 2px 10px;
      border-radius: 10rem;
    }
  }

  .delete-btn {
    border: 1px solid #F11D1D !important;
    font-weight: 400;
    font-size: 14px !important;
    color: #F11D1D !important;
    box-shadow: none !important;
    text-transform: capitalize;
    margin-right: 0 !important;

    &:hover {
      background-color: #F11D1D !important;
    }

  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  .documentation-header-selected-items {
    font-size: 14px;
    animation: 1s linear opacity;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-right: 15px;
    }

    .count {
      padding-top: 1px;
      color: #1F1F1F;
      margin: 0;

      span:nth-child(2) {
        opacity: 0.5;
      }
    }

    .exportBtn {
      height: 38px !important;
      font-size: 14px !important;
    }
  }
}