:root {
  --primary-color: #1F1F1F;
  --secondary-color: #fff;
  --error-color: #EF3A34;
  --success-color: #21A67A;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}

/* Regular */
@font-face {
  font-family: 'ABBvoice';
  src: url('./assets/styles/fonts/ABBvoice_Rg.ttf') format('truetype');
  font-weight: 400;
  /* Regular weight */
  font-style: normal;
}

/* Light */
@font-face {
  font-family: 'ABBvoice';
  src: url('./assets/styles/fonts/ABBvoice_Lt.ttf') format('truetype');
  font-weight: 400;
  /* Light weight */
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: 'ABBvoice';
  src: url('./assets/styles/fonts/ABBvoice_Md.ttf') format('truetype');
  font-weight: 500;
  /* Medium weight */
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'ABBvoice';
  src: url('./assets/styles/fonts/ABBvoice_Bd.ttf') format('ttf');
  font-weight: 700;
  /* Bold weight */
  font-style: normal;
}