.m-login.m-login--2.m-login-2--skin-1 .m-login__container {
  .m-login__head .m-login__title {
    color: #9f9fa1;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.6px;
    text-align: center;
  }

  .m-login__account.m-login__account-link {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.42px;
  }
}

.topLanguageSelector {
  width: auto;
  background: none;
  background: rgba(255, 255, 255, 0.20);
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  border-radius: 20px;
  padding: 0 10px;

  &:hover {
    border: none;
    background: rgba(255, 255, 255, 0.30);
  }

  .react-select__control {
    cursor: pointer;
    border-bottom: none !important;

    .react-select__indicator {
      padding: 0;
    }

    .react-select__placeholder,
    .react-select__single-value {
      color: #fff !important;
    }

    .react-select__indicators {
      .react-select__indicator {
        color: #fff !important;
      }
    }

    &:hover,
    &.react-select__control--is-focused {
      box-shadow: none;
      border: none !important;
    }
  }
}

.m-link.m-link--light.m-login__account-link {
  color: var(--primary-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer;

  &:hover {
    color: #282828 !important;
  }
}

.m-login.m-login--2.m-login-2--skin-1 {

  .m-login__wrapper {
    margin: 0 0 2rem 0;
    padding: 0.5% 2rem 1rem 2rem;

    .m-login__container {
      margin: 0 auto;
      width: 80%;

      .react-tabs__tab-list {
        border: none;
      }

      .m-login__head {
        margin-bottom: 15px;

        .m-login__desc {
          opacity: 1;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.42px;
        }
      }

      .m-have-account {
        margin-top: 15px;
        display: block;
        text-align: center;
      }

      .m-login__form {
        margin-top: 15px;
        margin-bottom: 15px;

        .m-login__form-action {
          margin-top: 30px;

          .m-login__btn {

            border: 1px solid #1F1F1F;
            background-color: #1F1F1F;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            padding: 16px 20px !important;

            &.m-login__btn.m-login__btn--primary {
              border: 1px solid var(--primary-color);
              background-color: var(--primary-color);
              color: #fff;
              font-size: 16px;
              font-weight: 500;

              &:hover,
              &:focus {
                border-color: #282828;
                background-color: #282828;
              }
            }

            &:hover {
              border-color: #282828;
              color: #fff;
              background-color: #282828;
              box-shadow: none !important;
            }

            &:focus {
              border-color: #282828;
              color: #fff;
              background-color: #282828;
            }
          }
        }

        .m-login__form-sub {
          &.terms-cond {
            margin-bottom: -20px !important;
            height: 30px !important;
          }

          &.mae {
            padding-left: 0;
            padding-right: 0;
            margin-right: -15px;
            margin-left: -15px;
          }

          .m-link {
            opacity: 1;
            color: #1F1F1F;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.42px;

            &:hover {
              color: #1F1F1F !important;
            }
          }

          .m-checkbox {
            opacity: 1;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.42px;
          }
        }

        .m-form__group {
          .form-control {
            background: transparent;
            border: none;
            border-radius: 0;
            margin-top: 0;
            padding: 0;
            border-bottom: 1px solid #5e6774;
            font-size: 16px !important;
            color: #fff;
            font-weight: 400;
            letter-spacing: 0.48px;
            margin-bottom: 0;

            &:focus {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.m-form__group {
  &>label {
    opacity: 1;
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 400;
  }

  .form-control {
    background: transparent;
    border: none;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
    border-bottom: 1px solid #5e6774;
    font-size: 16px !important;
    color: #fff;
    font-weight: 400;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }
}

.m-login__logo {
  margin-top: 20px;
}

.btn-focus.m-btn--air {
  box-shadow: 0 10px 20px 0 rgba(255, 184, 34, 0.21) !important;

  &:focus {
    box-shadow: none !important;
  }
}

.m-login__signin,
.m-login__forget-password {
  background: #fff;
  margin: 0 auto;
}

.m-login__account .qrscan {
  cursor: pointer;
}