.formSideBlock .has-danger {
  display: flex;
  position: relative;

  label {
    font-size: 1rem;
  }

  .open-color-picker {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    margin-top: 8px;
  }
}

.edit-tariff-form {
  .date-picker {
    position: relative;
    margin-bottom: 10px;

    .inputLabel {
      font-size: 12px !important;

      color: var(--primary-color) !important;
    }

    fieldset {
      border: none !important;
      border-radius: 0 !important;

      border-bottom: 1px solid #1F1F1F !important;
    }

    .Mui-focused {
      fieldset {
        border-bottom: 1px solid #fff !important;
      }
    }

    .Mui-disabled {
      opacity: 0.7;
      color: #1F1F1F !important;
      -webkit-text-fill-color: #1F1F1F !important;

      .datepicker-input {
        -webkit-text-fill-color: #1F1F1F !important;
        color: #1F1F1F !important;
      }
    }

    .MuiInputBase-root {
      width: auto;
    }

    .MuiInputBase-input {
      padding: 3px 0;
    }
  }

  input.datepicker-input {
    max-width: 100px;
    background-color: rgba(31, 31, 31, 0.05) !important;

    padding: 3px 0;

    font-size: 16px !important;
    border: none;
  }
}