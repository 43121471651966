.notification-frequency-container .checkmark,
.notification-type-error-container .checkmark {
    border: 1px solid var(--primary-color) !important;
}

.notification-frequency-container form .checkmark::after,
.notification-type-error-container form .checkmark::after {
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
}

.notification-frequency-container span,
.notification-type-error-container span {
    color: #8F939B;
    font-weight: 500;
}

.notification-frequency-container label,
.notification-type-error-container label {
    margin: 12px 0 0 0;
}

.notification-switch-field {
    display: flex;
    align-items: center;
}

.notification-switch-field p {
    color: #8F939B;
    margin: 0 0 0 20px;
}

.notification-switch-field .switcher {
    margin-top: 0;
}

.freq-text {
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.m-checkbox.every-new-error-label {
    color: #8F939B;
    font-weight: 500;
    margin-left: 6px;
}

.m-checkbox.every-new-error-label>span {
    border: 1px solid var(--primary-color) !important;
    margin-left: -5px;
}