.m-login__log {
  margin: 8px 0 9px 0;
}

.topbar-dashb .topBarLogo {
  max-height: 62px;
  margin-top: -8px;
  margin-bottom: -9px;
}

.topBarLogo {
  height: 40px;
}