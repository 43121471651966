.modal-body {
  .edit-car-form {
    position: relative;
    padding: 0 50px 20px 50px;

    .relink-car-btn {
      position: absolute;
      right: 50px;
      top: 10px;
      z-index: 5;
    }

    .support__url-btn {
      margin-bottom: 20px;
    }

    .select-wrapper {
      position: relative;
      margin: 20px 0;

      .switch-tooltip.select {
        position: absolute;
        top: 2px;
        right: 4px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }
  }
}