.cancel-button {
    display: inline-block;
    line-height: 18px;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;

    width: auto;
    padding: 11px 20px;
    margin: 0;

    border: 2px solid rgba(31, 31, 31, 0.25);
    border-radius: 48px;

    font-size: 14px;
    font-weight: 500;

    color: var(--primary-color);
    cursor: pointer;


    &:hover {
        background-color: #1F1F1F;
        color: #FFF;
        border-color: #1F1F1F;
    }

    &:focus {
        background-color: #1F1F1F;
        color: #FFF;
        border-color: #1F1F1F;
    }
}